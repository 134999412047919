<template>
  <div class="relative">
    <header
      :class="{' drop-shadow-[0_4px_8px_rgba(0_0_0_/_5%)]' : mobileMenuState < 1}"
      class="fixed z-50 block w-full overflow-y-auto bg-white px-4 py-2.5 md:relative md:h-full md:pt-10 lg:px-5"
    >
      <div class="hidden h-full flex-col justify-between gap-4 md:flex">
        <div>
          <div class="flex justify-center">
            <UiLogo />
          </div>
          <div class="-ml-1 mt-7 flex flex-col items-center">
            <nav class="mx-auto flex flex-col gap-1.5">
              <div
                v-for="(item, index) in menuItems"
                :key="index"
              >
                <NavItem
                  v-if="!item.desktopHidden"
                  :name="item.label"
                  :to="item.link"
                  :target="item.target"
                >
                  <UiIcon :name="item.icon" />
                </NavItem>
              </div>
            </nav>
          </div>
        </div>
        <div class="flex justify-center">
          <NuxtLink
            v-if="user && !isPartner"
            :to="{name: 'webshop'}"
            class="fuzzy btn btn-primary"
          >
            <span>Naar de webshop</span>
            <IconsShoppingCart class="" />
          </NuxtLink>
        </div>
        <div class="mx-auto mb-7 space-y-2.5">
          <NavItem
            name="Terug naar KHN.nl"
            to="https://khn.nl"
            class="-ml-1"
          >
            <IconsGlobeAlt />
          </NavItem>
          <button
            v-if="user"
            class="-ml-1 flex items-center gap-2 border-l-4 border-white px-4 py-2.5 text-sm font-bold no-underline transition hover:border-l-secondary hover:text-secondary lg:text-base"
            @click="signOut"
          >
            <IconsLogout />
            Uitloggen
          </button>
          <NuxtLink
            v-else
            class="btn btn-primary w-full"
            to="/auth/login"
          >
            Inloggen
            <IconsUser />
          </NuxtLink>
        </div>
      </div>
      <div class="flex justify-between gap-4 md:hidden">
        <UiLogo small />
        <div class="flex w-auto items-center space-x-7">
          <div class="relative size-6">
            <NuxtLink :to="{name: 'webshop-cart'}">
              <IconsShoppingCart class="size-6" />
            </NuxtLink>
            <div
              v-if="shoppingCartSize > 0"
              class="absolute -right-2 -top-2 flex size-5 items-center justify-center rounded-full bg-secondary"
            >
              <span class="text-xs text-white">
                {{ shoppingCartSize.toString().padStart(2, '0') }}
              </span>
            </div>
          </div>
          <button
            class="btn btn-primary flex gap-2"
            @click="mobileMenuState = mobileMenuState > 0 ? 0 : 1"
          >
            {{ mobileMenuState === 0 ? 'Menu' : 'Sluiten' }}
            <IconsHamburger v-if="mobileMenuState === 0" />
            <IconsCross v-else />
          </button>
        </div>
      </div>
    </header>
    <UiMenuMobile
      :class="mobileMenuState === 1 ? 'translate-y-0' : '-translate-y-full'"
      :menu-items-mobile="menuItems"
    />
  </div>
</template>

<script lang="ts" setup>
import type {Ref} from 'vue';
import {useShoppingCart} from '~/composables/shoppingCart';

const route = useRoute();
const user = useSofieUserWhenAuthenticated();
const {isPartner, isEmployee, isManager} = useRole();

const {signOut} = useSession();

const menuItemsLoggedIn = [
  {
    label: 'Dashboard',
    link: {name: 'dashboard'},
    icon: 'home',
  },
  {
    label: 'Account',
    link: {name: 'account'},
    icon: 'user-circle',
    submenu: {
      items: [
        {
          label: 'Mijn profiel',
          link: {name: 'account'},
        },
        {
          label: 'Bedrijfsgegevens',
          link: {name: 'account-bedrijfsgegevens'},
        },
        {
          label: 'Lidmaatschap',
          link: {name: 'account-lidmaatschap'},
        },
        /* {
          label: 'Ledenvoordelen',
          link: {name: 'account-ledenvoordelen'},
        }, */
        {
          label: 'Evenementen',
          link: {name: 'account-evenementen'},
        },
        {
          label: 'Mijn facturen',
          link: {name: 'account-facturen'},
        },
        {
          label: 'Communicatievoorkeuren',
          link: {name: 'account-communicatievoorkeuren'},
        },
        isEmployee.value ? {
          label: 'Nieuwsbrief&shy;voorkeuren',
          link: {name: 'account-nieuwsbriefvoorkeuren'},
        } : null,
      ].filter(l => l),
    },
  },
  /* {
    label: 'Ledenvoordelen',
    link: {name: 'ledenvoordelen'},
    icon: 'scale',
  }, */
  !isPartner.value ? {
    label: 'Evenementen',
    link: {name: 'evenementen'},
    icon: 'calendar',
  } : null,
  !isPartner.value ? {
    label: 'Kennisbank',
    link: {name: 'kennisbank'},
    icon: 'newspaper',
  } : null,
  isManager.value ? {
    label: 'Voor bestuurders',
    link: {name: 'bestuurders'},
    icon: 'office-building',
    submenu: {
      items: [
        {
          label: 'Dashboard',
          link: {name: 'bestuurders'},
        },
        {
          label: 'Bestuurdersacademie',
          link: {name: 'bestuurders-bestuurdersacademie'},
        },
        {
          label: 'Dossiers / Documenten',
          link: {name: 'bestuurders-dossiers-documenten'},
        },
        {
          label: 'Projectenpot',
          link: {path: '/bestuurders/projectenpot'},
        },
        {
          label: 'Ballotage',
          link: {path: '/bestuurders/ballotage'},
        },
      ],
    },
  } : null,
  isPartner.value ? {
    label: 'Verkoopkansen',
    link: {name: 'verkoopkansen'},
    icon: 'scale',
  } : null,
  isPartner.value ? {
    label: 'Klanten',
    link: {name: 'klanten'},
    icon: 'group',
  } : null,
  !isPartner.value ? {
    label: 'Vacatures',
    link: 'https://jobs.khn.nl/auth/khn',
    icon: 'group',
    target: '_blank',
  } : null,
  isPartner.value ? null : {
    label: 'Naar webshop',
    link: {name: 'webshop'},
    icon: 'shopping-cart',
    highlight: true,
    desktopHidden: true,
  },
].filter(l => l);

const menuItemsGuest = [
  {
    label: 'KHN Webshop',
    link: {name: 'webshop'},
    icon: 'shopping-cart',
  },
  {
    label: 'Evenementen',
    link: {name: 'evenementen'},
    icon: 'calendar',
  },
];

const menuItems = computed(() => user.value ? menuItemsLoggedIn : menuItemsGuest);

const shoppingCartSize: Ref<number> = useShoppingCart().shoppingCartSize;

const mobileMenuState = ref(0);

watch(() => route.path, () => {
  mobileMenuState.value = 0;
});
</script>
